
import { Component, Vue } from "vue-property-decorator";
import {
  WebData,
  webError,
  webLoading,
  webNotAsked,
  webSuccess,
} from "@/api/web-data";
import { AxiosError } from "axios";
import {
  getSniperPlatforms,
  Platform,
  SniperPlatform,
  SniperPlatformsResponse,
  updateSniperPlatform,
} from "@/api/admin-api";
import LoadingIndicator from "@/components/ui/LoadingIndicator.vue";
import SniperPlatformsTable from "@/components/sniper/SniperPlatformsTable.vue";

@Component({
  components: {
    SniperPlatformsTable,
    LoadingIndicator,
  },
})
export default class Sniper extends Vue {
  private readonly VALIDATION_ERROR_STATUS_CODE = 422;

  platforms: WebData<AxiosError, SniperPlatformsResponse> = webNotAsked();

  created(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    this.platforms = webLoading();
    getSniperPlatforms().then(this.loadingSuccess).catch(this.loadingError);
  }

  private loadingSuccess(response: SniperPlatformsResponse) {
    this.platforms = webSuccess(response);
  }

  private loadingError(error: AxiosError) {
    this.platforms = webError(error);
  }

  private loadingSuccessUpdate(_: Platform): Promise<void> {
    this.$noty.success("The platform has been updated.");
    return this.fetchData();
  }

  private loadingErrorSave(error: AxiosError) {
    if (this.isValidationError(error)) {
      this.platforms = webError(error.response.data.error);
      this.$noty.error("Failed to validate platform data.");
      return;
    }
    this.platforms = webError(error);
    this.$noty.error("Failure to save platform data");
  }

  userClickedSavePlatform(platform: SniperPlatform): Promise<void> {
    const { name, affiliateLink, affiliateCashBack } = platform;

    const emptyStringToNull = (str: string) => (str === "" ? null : str);

    const updateRequest = {
      name,
      affiliateLink: emptyStringToNull(affiliateLink),
      affiliateCashBack: emptyStringToNull(affiliateCashBack),
    };

    return updateSniperPlatform(platform.id, updateRequest)
      .then(this.loadingSuccessUpdate)
      .catch(this.loadingErrorSave);
  }

  private isValidationError(error: AxiosError) {
    return error?.response?.status === this.VALIDATION_ERROR_STATUS_CODE;
  }
}
