import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  formatAuthorizationHeaderWithBearerToken,
  getAccessTokenFromStorage,
} from "@/service/auth";
import { logoutFromKeycloak } from "@/service/keycloak-service";

const BASE_URL = process.env.VUE_APP_API_URL || "ENV_NOT_LOADED";
const ACCEPT_HEADER = "application/com.beyondp2p.api-v1.0+json";

export const client: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}/admin`,
  timeout: 60000,
  headers: {
    Accept: ACCEPT_HEADER,
    ...(getAccessTokenFromStorage() != null && {
      // Loads Authorization header here from storage; otherwise dynamically upon login.
      Authorization: formatAuthorizationHeaderWithBearerToken(
        getAccessTokenFromStorage()
      ),
    }),
  },
});

// Register global error handler
client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data.status === 401) {
      // Auth failed
      return logoutFromKeycloak().then(() => {
        window.location.assign("/");
        return Promise.reject(error);
      });
    }
    // whatever you want to do with the error
    return Promise.reject(error);
  }
);

function responseToData<D>(response: AxiosResponse<D>): D {
  return response.data;
}

// constants
export type Country = string;

export type InvestingIntoQualifier = string;

export interface InvestingInto {
  name: InvestingIntoQualifier;
  displayName: string;
}

export type Currency = string;

export type AffiliatePartner = string;

export type TelegramBotAlert = string;

export type SubscriberFilter = string;

/**
 * This is a dummy value. When we have introduced the field affiliatePartner, we need to provide a
 * value to uphold @NonNull relationship. Using this enum value will cause an exception to be
 * thrown.
 */
export const MIGRATION_AFFILIATE_PARTNER: AffiliatePartner = "MIGRATION";

export interface ApiConstants {
  countries: Country[];
  investingInto: InvestingInto[];
  currencies: Currency[];
  featureTypes: FeatureType[];
  investmentStructures: InvestmentStructure[];
  originatorTypes: OriginatorType[];
  collaterals: Collateral[];
  affiliatePartners: AffiliatePartner[];
  telegramBotAlerts: TelegramBotAlert[];
  subscriberFilters: SubscriberFilter[];
}

export const EMPTY_API_CONSTANTS: ApiConstants = {
  countries: [],
  investingInto: [],
  currencies: [],
  featureTypes: [],
  investmentStructures: [],
  originatorTypes: [],
  collaterals: [],
  affiliatePartners: [],
  telegramBotAlerts: [],
  subscriberFilters: [],
};

export async function getApiConstants(): Promise<ApiConstants> {
  return client.get("/constants").then(responseToData);
}

// platforms

export type PlatformId = string;
export type Qualifier = string;

export interface PlatformsTableResponse {
  platforms: PlatformsTableRow[];
}

export interface PlatformsTableRow {
  id: PlatformId;
  slug: string;
  name: string;
}

export async function getPlatformsTable(): Promise<PlatformsTableResponse> {
  return client.get("/platforms").then(responseToData);
}

export interface PlatformLookup {
  name: string;
}

export interface PlatformsMap {
  [platformId: string]: PlatformLookup;
}

export async function getPlatformsMap(): Promise<PlatformsMap> {
  return client.get("/platforms/map").then(responseToData);
}

export interface Platform {
  id: PlatformId;
  qualifier: Qualifier;
  name: string;
  slug: string;
  showReview: boolean;
  showComparison: boolean;
  showMarketData: boolean;
  sharesDailyStatistics: boolean;
  affiliateLink: string;
  affiliateCashBack: string | null;
  affiliateNotRecommend: boolean;
  description: string;
  country: Country;
  investingInto: InvestingInto[];
}

export async function getPlatformById(id: PlatformId): Promise<Platform> {
  return client.get(`/platforms/${id}`).then(responseToData);
}

export interface CreatePlatformRequest {
  qualifier: Qualifier;
  name: string;
  slug: string;
  showReview: boolean;
  showComparison: boolean;
  showMarketData: boolean;
  sharesDailyStatistics: boolean;
  affiliateLink: string;
  affiliateCashBack: string | null;
  affiliateNotRecommend: boolean;
  description: string;
  country: Country;
}

export async function createPlatform(
  payload: CreatePlatformRequest
): Promise<Platform> {
  return client.post(`/platforms`, payload).then(responseToData);
}

export interface UpdatePlatformRequest {
  name: string;
  showReview: boolean;
  showComparison: boolean;
  showMarketData: boolean;
  sharesDailyStatistics: boolean;
  affiliateLink: string;
  affiliateCashBack: string | null;
  affiliateNotRecommend: boolean;
  description: string;
  country: Country;
}

export async function updatePlatform(
  id: PlatformId,
  payload: UpdatePlatformRequest
): Promise<Platform> {
  return client.put(`/platforms/${id}`, payload).then(responseToData);
}

// ratings

export interface PlatformRating {
  averageRating: number;
  averageRatingHalf: number;
  ratingsCount: number;
  ownPlatformRating: OwnPlatformRating | null;
  externalPlatformRatings: ExternalPlatformRating[];
}

export interface OwnPlatformRating {
  id: string;
  stars: number;
}

export interface ExternalPlatformRating {
  id: string;
  url: string;
  stars: number;
}

export async function getRatingByPlatformId(
  platformId: PlatformId
): Promise<PlatformRating> {
  return client.get(`/platforms/${platformId}/ratings`).then(responseToData);
}

export interface CreateOwnPlatformRatingRequest {
  stars: number;
}

export async function createOwnPlatformRating(
  platformId: PlatformId,
  payload: CreateOwnPlatformRatingRequest
): Promise<OwnPlatformRating> {
  return client
    .post(`/platforms/${platformId}/ratings/own`, payload)
    .then(responseToData);
}

export interface UpdateOwnPlatformRatingRequest {
  stars: number;
}

export async function updateOwnPlatformRating(
  platformId: PlatformId,
  payload: UpdateOwnPlatformRatingRequest
): Promise<OwnPlatformRating> {
  return client
    .put(`/platforms/${platformId}/ratings/own`, payload)
    .then(responseToData);
}

export async function deleteOwnPlatformRating(
  platformId: PlatformId
): Promise<void> {
  return client
    .delete(`/platforms/${platformId}/ratings/own`)
    .then(responseToData);
}

export interface CreateExternalPlatformRatingRequest {
  url: string;
  stars: number;
}

export async function createExternalPlatformRating(
  platformId: PlatformId,
  payload: CreateExternalPlatformRatingRequest
): Promise<ExternalPlatformRating> {
  return client
    .post(`/platforms/${platformId}/ratings/external`, payload)
    .then(responseToData);
}

export interface UpdateExternalPlatformRatingRequest {
  url: string;
  stars: number;
}

export async function updateExternalPlatformRating(
  platformId: PlatformId,
  ratingId: string,
  payload: UpdateExternalPlatformRatingRequest
): Promise<ExternalPlatformRating> {
  return client
    .put(`/platforms/${platformId}/ratings/external/${ratingId}`, payload)
    .then(responseToData);
}

export async function deleteExternalPlatformRating(
  platformId: PlatformId,
  ratingId: string
): Promise<void> {
  return client
    .delete(`/platforms/${platformId}/ratings/external/${ratingId}`)
    .then(responseToData);
}

// investments

export type PlatformInvestmentsId = string;

export type InvestmentStructureQualifier = string;

export interface InvestmentStructure {
  name: InvestmentStructureQualifier;
  displayName: string;
  relationship: string;
  relationshipShort: string;
}

export type OriginatorTypeQualifier = string;

export interface OriginatorType {
  name: OriginatorTypeQualifier;
  displayName: string;
}

export type CollateralQualifier = string;

export interface Collateral {
  name: CollateralQualifier;
  displayName: string;
}

export interface PlatformInvestments {
  id: PlatformInvestmentsId;
  investmentStructures: InvestmentStructure[];
  investingInto: InvestingInto[];
  originatorTypes: OriginatorType[];
  interestRatesLower: number;
  interestRatesUpper: number;
  numberOfCountries: number;
  numberOfOriginators: number;
  currencies: Currency[];
  minimumInvestment: number;
  minimumInvestmentCurrency: Currency;
  collaterals: Collateral[];
  maximumLoanToValue: number;
}

export async function getInvestmentsByPlatformId(
  id: PlatformId
): Promise<PlatformInvestments> {
  return client.get(`/platforms/${id}/investments`).then(responseToData);
}

export interface CreatePlatformInvestmentsRequest {
  investmentStructures: InvestmentStructureQualifier[];
  investingInto: InvestingIntoQualifier[];
  originatorTypes: OriginatorTypeQualifier[];
  interestRatesLower: number;
  interestRatesUpper: number;
  numberOfCountries: number;
  numberOfOriginators: number;
  currencies: Currency[];
  minimumInvestment: number;
  minimumInvestmentCurrency: Currency;
  collaterals: CollateralQualifier[];
  maximumLoanToValue: number;
}

export async function createPlatformInvestments(
  platformId: PlatformId,
  payload: CreatePlatformInvestmentsRequest
): Promise<PlatformInvestments> {
  return client
    .post(`/platforms/${platformId}/investments`, payload)
    .then(responseToData);
}

export interface UpdatePlatformInvestmentsRequest {
  investmentStructures: InvestmentStructureQualifier[];
  investingInto: InvestingIntoQualifier[];
  originatorTypes: OriginatorTypeQualifier[];
  interestRatesLower: number;
  interestRatesUpper: number;
  numberOfCountries: number;
  numberOfOriginators: number;
  currencies: Currency[];
  minimumInvestment: number;
  minimumInvestmentCurrency: Currency;
  collaterals: CollateralQualifier[];
  maximumLoanToValue: number;
}

export async function updatePlatformInvestments(
  platformId: PlatformId,
  investmentsId: PlatformInvestmentsId,
  payload: UpdatePlatformInvestmentsRequest
): Promise<PlatformInvestments> {
  return client
    .put(`/platforms/${platformId}/investments/${investmentsId}`, payload)
    .then(responseToData);
}

// statistics

export type PlatformStatisticsId = string;

export interface PlatformStatisticsResponse {
  statistics: PlatformStatistics[];
}

export interface PlatformStatistics {
  id: PlatformStatisticsId;
  createdAt: Date;
  createdBy: string;
  loansTotalFunded: number;
  loansOutstanding: number;
  loansCurrent: number;
  loansDaysLate1To15: number;
  loansDaysLate16To30: number;
  loansDaysLate31To60: number;
  loansDaysLate60Plus: number;
  loansDaysLate: number;
  loansDefaultedInRecovery: number;
  loansDefaultedRecovered: number;
  loansDefaultedWrittenOff: number;
  investors: number;
  averageNetAnnualReturn: number;
  averageAmountPerInvestor: number;
  principalReturned: number;
  interestEarned: number;
  lateFeesEarned: number;
  currency: string;
}

export async function getStatisticsByPlatformId(
  id: PlatformId
): Promise<PlatformStatisticsResponse> {
  return client.get(`/platforms/${id}/statistics`).then(responseToData);
}

export async function getStatisticsByIds(
  platformId: PlatformId,
  statisticsId: PlatformStatisticsId
): Promise<PlatformStatistics> {
  return client
    .get(`/platforms/${platformId}/statistics/${statisticsId}`)
    .then(responseToData);
}

export interface CreatePlatformStatisticsRequest {
  loansTotalFunded: number;
  loansOutstanding: number;
  loansCurrent: number;
  loansDaysLate: number;
  loansDaysLate1To15: number;
  loansDaysLate16To30: number;
  loansDaysLate31To60: number;
  loansDaysLate60Plus: number;
  loansDefaultedInRecovery: number;
  loansDefaultedRecovered: number;
  loansDefaultedWrittenOff: number;
  investors: number;
  averageNetAnnualReturn: number;
  averageAmountPerInvestor: number;
  principalReturned: number;
  interestEarned: number;
  lateFeesEarned: number;
}

export async function createPlatformStatistics(
  platformId: PlatformId,
  payload: CreatePlatformStatisticsRequest
): Promise<PlatformStatistics> {
  return client
    .post(`/platforms/${platformId}/statistics`, payload)
    .then(responseToData);
}

export interface UpdatePlatformStatisticsRequest {
  loansTotalFunded: number;
  loansOutstanding: number;
  loansCurrent: number;
  loansDaysLate1To15: number;
  loansDaysLate16To30: number;
  loansDaysLate31To60: number;
  loansDaysLate60Plus: number;
  loansDaysLate: number;
  loansDefaultedInRecovery: number;
  loansDefaultedRecovered: number;
  loansDefaultedWrittenOff: number;
  investors: number;
  averageNetAnnualReturn: number;
  averageAmountPerInvestor: number;
  principalReturned: number;
  interestEarned: number;
  lateFeesEarned: number;
}

export async function updatePlatformStatistics(
  platformId: PlatformId,
  statisticsId: PlatformStatisticsId,
  payload: UpdatePlatformStatisticsRequest
): Promise<PlatformStatistics> {
  return client
    .put(`/platforms/${platformId}/statistics/${statisticsId}`, payload)
    .then(responseToData);
}

// features

export type PlatformFeatureId = string;

export type FeatureTypeQualifier = string;

export interface FeatureType {
  name: FeatureTypeQualifier;
  guidePage: GuidePage;
  guideUrl: string;
  label: string;
}

export type FeatureAvailability = string;

export type GuidePage = string;

export interface PlatformFeaturesResponse {
  availableFeatures: AvailableFeature[];
  notAvailableFeatures: NotAvailableFeature[];
}

export interface AvailableFeature extends FeatureType {
  kind: "available";
  id: PlatformFeatureId;
  availability: FeatureAvailability;
  createdAt: Date;
  updatedAt?: Date;
  description: string;
  names: string[];
}

export interface NotAvailableFeature extends FeatureType {
  kind: "not-available";
  id: PlatformFeatureId;
  availability: FeatureAvailability;
}

export async function getFeaturesByPlatformId(
  id: PlatformId
): Promise<PlatformFeaturesResponse> {
  return client.get(`/platforms/${id}/features`).then(responseToData);
}

export async function getFeatureByIds(
  platformId: PlatformId,
  featureId: PlatformFeatureId
): Promise<AvailableFeature> {
  return client
    .get(`/platforms/${platformId}/features/${featureId}`)
    .then(responseToData);
}

export interface CreatePlatformFeatureRequest {
  type: FeatureTypeQualifier;
  description: string;
  names: string[];
}

export async function createPlatformFeature(
  platformId: PlatformId,
  payload: CreatePlatformFeatureRequest
): Promise<AvailableFeature> {
  return client
    .post(`/platforms/${platformId}/features`, payload)
    .then(responseToData);
}

export interface UpdatePlatformFeatureRequest {
  description: string;
  names: string[];
}

export async function updatePlatformFeature(
  platformId: PlatformId,
  featureId: PlatformFeatureId,
  payload: UpdatePlatformFeatureRequest
): Promise<AvailableFeature> {
  return client
    .put(`/platforms/${platformId}/features/${featureId}`, payload)
    .then(responseToData);
}

export async function deletePlatformFeature(
  platformId: PlatformId,
  featureId: PlatformFeatureId
): Promise<void> {
  return client.delete(`/platforms/${platformId}/features/${featureId}`);
}

// assets

export interface PlatformAssetLogo {
  contentType: string;
  data: string;
}

export async function getLogoByPlatformId(
  platformId: PlatformId
): Promise<PlatformAssetLogo> {
  return client
    .get(`/platforms/${platformId}/assets/logo`)
    .then(responseToData);
}

export async function uploadLogoForPlatform(
  platformId: PlatformId,
  file: any
): Promise<PlatformAssetLogo> {
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return client
    .post(`/platforms/${platformId}/assets/logo`, formData, config)
    .then(responseToData);
}

// publisher settings
export type PlatformPublisherSettingsId = string;

export interface PlatformPublisherSettings {
  id: PlatformPublisherSettingsId;
  platformId: PlatformId;
  affiliatePartner: string;
  affiliateLink: string;
}

export async function getPublisherSettingsByPlatformId(
  platformId: PlatformId
): Promise<PlatformPublisherSettings> {
  return client
    .get(`/platforms/${platformId}/publisher-settings`)
    .then(responseToData);
}

export interface CreatePlatformPublisherSettingsRequest {
  affiliatePartner: AffiliatePartner;
  affiliateLink: string;
}

export async function createPlatformPublisherSettings(
  platformId: PlatformId,
  payload: CreatePlatformPublisherSettingsRequest
): Promise<PlatformPublisherSettings> {
  return client
    .post(`/platforms/${platformId}/publisher-settings`, payload)
    .then(responseToData);
}

export interface UpdatePlatformPublisherSettingsRequest {
  affiliatePartner: AffiliatePartner;
  affiliateLink: string;
}

export async function updatePlatformPublisherSettings(
  platformId: PlatformId,
  payload: UpdatePlatformPublisherSettingsRequest
): Promise<PlatformPublisherSettings> {
  return client
    .put(`/platforms/${platformId}/publisher-settings`, payload)
    .then(responseToData);
}

// API

export interface ExistsClientForPlatform {
  existsClientForPlatform: boolean;
}

export async function existsClientForPlatform(
  platformId: PlatformId
): Promise<ExistsClientForPlatform> {
  return client.get(`/platforms/${platformId}/clients`).then(responseToData);
}

export interface PlatformClientCreateRequest {
  email: string;
  baseUrl: string;
}

export interface PlatformClientCreateResponse {
  clientId: string;
  clientSecret: string;
}

export async function createPlatformClient(
  platformId: PlatformId,
  payload: PlatformClientCreateRequest
): Promise<PlatformClientCreateResponse> {
  return client
    .post(`/platforms/${platformId}/clients`, payload)
    .then(responseToData);
}

// Data

export async function runPlatformStatisticsUpdater(): Promise<void> {
  return client
    .post("/data/updaters/platforms/statistics")
    .then(responseToData);
}

export async function runMintosLoanOriginatorsUpdater(): Promise<void> {
  return client
    .post("/data/updaters/mintos/loan-originators")
    .then(responseToData);
}

export async function deleteMintosLoanOriginatorsStatisticsForToday(): Promise<void> {
  return client
    .delete("/data/updaters/mintos/loan-originators/today")
    .then(responseToData);
}

// telegram bot
export interface TelegramBotStatisticsResponse {
  uniqueSubscribers: number;
  byAlert: { alert: string; count: number }[];
}

export async function getTelegramBotStatistics(): Promise<TelegramBotStatisticsResponse> {
  return client.get("/telegram-bot/statistics").then(responseToData);
}

export enum SubscriberFilterEnum {
  ALL_SUBSCRIBERS = "ALL_SUBSCRIBERS",
  FREE_SUBSCRIBERS_ONLY = "FREE_SUBSCRIBERS_ONLY",
  PREMIUM_SUBSCRIBERS_ONLY = "PREMIUM_SUBSCRIBERS_ONLY",
  SINGLE_SUBSCRIBER = "SINGLE_SUBSCRIBER",
  SPECIFIC_ALERT_SUBSCRIBERS = "SPECIFIC_ALERT_SUBSCRIBERS",
  ADMINS_ONLY = "ADMINS_ONLY",
}

export type SubscriberFilterPayload =
  // `Record<string, never>` is a null-object ({})
  Record<string, never> | { telegramUserId: number } | { alert: string };

export interface SendMessageToTelegramBotSubscribersRequest {
  message: string;
  subscriberFilter: string;
  subscriberFilterPayload: SubscriberFilterPayload;
  showUpvoteButton: boolean;
  publishToProdBot: boolean;
}

export async function sendMessageToBotSubscribers(
  payload: SendMessageToTelegramBotSubscribersRequest
): Promise<void> {
  return client.post("/telegram-bot/message", payload).then(responseToData);
}

// P2P Sniper

export async function getSniperStatistics(): Promise<SniperStatisticsResponse> {
  return client.get("/sniper/statistics").then(responseToData);
}

export interface SniperStatisticsResponse {
  entries: SniperStatistics[];
}

export interface SniperStatistics {
  date: string;
  usersCount: number;
  alertsSentCount: number;
}

export async function getSniperPlatforms(): Promise<SniperPlatformsResponse> {
  return client.get("/sniper/platforms").then(responseToData);
}

export interface SniperPlatformsResponse {
  platforms: SniperPlatform[];
}

export interface SniperPlatform {
  readonly id: string;
  readonly qualifier: string;
  name: string;
  affiliateLink: string;
  affiliateCashBack: string;
}

export async function updateSniperPlatform(
  platformId: string,
  updateRequest: SniperPlatformUpdateRequest
): Promise<SniperPlatform> {
  return client
    .put(`/sniper/platforms/${platformId}`, updateRequest)
    .then(responseToData);
}

export interface SniperPlatformUpdateRequest {
  name: string;
  affiliateLink: string;
  affiliateCashBack: string;
}
